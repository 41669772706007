import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
//import "animate.css/animate.min.css";
// Lazy loading
import LazyLoad from "react-lazyload";

import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
const imageOfAppWithMap = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_auto/sales/product-screenshots/driver-app-with-map.png";
const imageOfTrackingDetail = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_auto/sales/product-screenshots/tracking-detail.png";
//const imageOfInvoiceHeader = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_auto/sales/product-screenshots/driver-app-invoice-view.png";

//import imageOfDriverHoldingPhone from "assets/img/people/driver-holding-phone.png";
const imageOfDriverHoldingPhone = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_90/sales/people/driver-holding-phone.png";

const useStyles = makeStyles(contentStyle);

export default function SectionContent() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Reclaim your Time</h3>
              <h6 className={classes.description}>
                Put your effort into things that really matter
              </h6>
              <h5 className={classes.description}>
              One platform to get it all done. No faffing between separate systems trying to 
              get them to talk to each other. No double-entry or paper records. 
              Your team can focus on the work at-hand and move on! 
              Fyneworks gets your data where it needs to go.
              </h5>
            {/* </div> */}
            {/* <div className={classes.sectionDescription}> */}
              <h3 className={classes.title}>Let the work flow</h3>
              <h6 className={classes.description}>
                Effortless communication for everyone
              </h6>
              <h5 className={classes.description}>
              Monitor your workforce out in the field and allocate jobs on the fly. 
              Instant notifications and flexible management options help 
              your business stay nimble.
              Track progress, <b>collect signatures, audio, photos and videos while you're on-the-job</b>.{" "}
              </h5>
              {/* <br/>
              With contactless signatures, you'll be 
              <img src="https://res.cloudinary.com/fynewo/image/upload/w_200/sales/product-screenshots/shared-signature-customer.png"/> */}
            </div>
          </GridItem>
          <GridItem md={7} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <div className={classes.animeAreaImg}>
                <ScrollAnimation animateIn="fadeInUp">
                  <LazyLoad>
                    <img
                      src={imageOfDriverHoldingPhone}
                      alt="Driver Holding Phone App"
                      className={classes.areaImg}
                    />
                  </LazyLoad>
                </ScrollAnimation>
              </div>
              <div className={classes.animeInfoImg}>
                <ScrollAnimation animateIn="fadeInUp">
                  <LazyLoad>
                    <img
                      src={imageOfTrackingDetail}
                      alt="Job Tracking View"
                      className={classes.infoImg}
                    />
                  </LazyLoad>
                </ScrollAnimation>
              </div>
              {/* <LazyLoad> */}
                <img
                  className={classes.ipadImg}
                  src={imageOfAppWithMap}
                  alt="Fyneworks app with map"
                />
              {/* </LazyLoad> */}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
