import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import componentsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/componentsStyle.js";

import LazyLoad from 'react-lazyload';
const shoppingCartImage = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_85,w_640/sales/product-screenshots/checkout.png";
const shareButtonImage = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_85/sales/assets-for-demo/presentationViewSectionComponent/share-btn.jpg";
const twitterImage = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_85/sales/assets-for-demo/presentationViewSectionComponent/coloured-card.jpg";
const iconsImage = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_85/sales/assets-for-demo/presentationViewSectionComponent/social-row.jpg";
const repostImage = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_85/sales/assets-for-demo/presentationViewSectionComponent/pin-btn.jpg";
const macbookImage = "https://res.cloudinary.com/fynewo/image/upload/q_auto,f_auto,w_2000/sales/product-screenshots/macbook-with-filterworks.png";
const imageOfCustomerSeviceAgent = "https://res.cloudinary.com/fynewo/image/upload/q_auto,f_auto,w_480/sales/people/customer-service-agent.png";

const useStyles = makeStyles(componentsStyle);

export default function SectionComponents({ ...rest }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container} { ...rest }>
        <GridContainer justify="center">
          <GridItem md={5} lg={5} sm={12} xs={12}>
            <h3 className={classes.title}>Stay on top of everything</h3>
            <h6 className={classes.description}>
              Excellent lead tracking so you don't miss a beat
            </h6>
            <h5 className={classes.description}>
              With Fyneworks you'll know exactly where your leads came from, you can track
              their progress, collaborate with the client, attach purchase orders, 
              request approvals, etc.
            </h5>
            <h3 className={classes.title}>Maximise your potential</h3>
            <h6 className={classes.description}>
              Turn your leads into sales and happy customers
            </h6>
            <h5 className={classes.description}>
              Manage your sales with powerful features you won't find in any other
              system - subscriptions, scheduled invoicing, credit control, debt consolidation and more. 
              And your clients can access their data through their own dashboards.
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6} className={classes.mlAuto}>
            <LazyLoad>
              <div className={classes.imageContainer}>
                <img
                  src={macbookImage}
                  alt="macbook"
                  className={classes.componentsMacbook}
                />
                <img
                  src={shoppingCartImage}
                  alt="macbook"
                  className={classes.shoppingCart}
                />
                <img
                  src={shareButtonImage}
                  alt="macbook"
                  className={classes.shareButton}
                />
                <img
                  src={imageOfCustomerSeviceAgent}
                  alt="macbook"
                  className={classes.cardImage}
                />
                <img
                  src={twitterImage}
                  alt="macbook"
                  className={classes.twitterImage}
                />
                <img
                  src={iconsImage}
                  alt="macbook"
                  className={classes.iconsImage}
                />
                <img
                  src={repostImage}
                  alt="macbook"
                  className={classes.repostImage}
                />
              </div>
            </LazyLoad>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
