import React from "react";
// core components
import Footer from "components/Footer/Footer.js";
// @material-ui/core components
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
// import { makeStyles } from "@material-ui/core/styles";
// images

// local styles
//import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
//import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
//const useStyles = makeStyles({...presentationStyle, ...landingPageStyle});

export default function SectionFooter({ classes }) {
  //const classes = useStyles();
  return (
    <div>
      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <a
                href="https://fyne.works/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerBrand}
              >
                Fyneworks
              </a>
            </div>
            {/*
					<div className={classes.pullCenter}>
						<List className={classes.list}>
							<ListItem className={classes.inlineBlock}>
								<a
									href="https://fyne.works/about"
									target="_blank" rel="noopener noreferrer"
									className={classes.block}
								>
									About us
								</a>
							</ListItem>
							<ListItem className={classes.inlineBlock}>
								<a
									href="https://fyne.works/contact"
									target="_blank" rel="noopener noreferrer"
									className={classes.block}
								>
									Contact
								</a>
							</ListItem>
						</List>
					</div>
					*/}
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    href="https://twitter.com/fyneworks"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="twitter"
                    justIcon
                    simple
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://www.facebook.com/fyneworks"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="facebook"
                    justIcon
                    simple
                  >
                    <i className="fab fa-facebook" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://www.linkedin.com/company/fyneworks"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="linkedin"
                    justIcon
                    simple
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://instagram.com/fyneworks"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="instagram"
                    justIcon
                    simple
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                </li>
                <li>
                  {/*
                    See trello card about getting custom youtube name
                    https://trello.com/c/VqsIMNSA/29-custom-youtube-channel-name
                  */}
                  <Button
                    href="https://www.youtube.com/channel/UCG07kfr0PzG5SBxu2j1lwcw"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="youtube"
                    justIcon
                    simple
                  >
                    <i className="fab fa-youtube" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      />
    </div>
  );
}
