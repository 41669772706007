import React, { useCallback, useState } from "react";
// react components used to create a google map
//import {
//  withScriptjs,
//  withGoogleMap,
//  GoogleMap,
//  Marker
//} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// @material-ui/icons
//import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Check from "@material-ui/icons/Check";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import { Analytics } from "modules/analytics"; //'@fyne/ui/analytics';

import { useFormik } from "formik";
import { validationSchema } from "modules/validation";

//import city from "assets/img/examples/city.jpg";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles(contactsStyle);

const city = "https://res.cloudinary.com/fynewo/image/upload/q_auto,f_auto,q_10/sales/backgrounds/city.jpg"

export default function SectionContacts({ initialValues = {}, ...rest }) {
  const [checked, setChecked] = React.useState([]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();

  //const server = (window.location.hostname.match(/localhost/)?'http://localhost:8888':'');
  const [isSubmitted, setSubmitted] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const action = "/.netlify/functions/send-contact-email";
  const onSubmit = async (values, Formik) => {
    console.log("onSubmit", { values, Formik, analytics: Analytics() });
    setSubmitting(true);
    const response = await (await fetch(action, {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(values)
    })).json();
    if (response) {
      setTimeout(() => {
        setSubmitting(false);
        setSubmitted(true);

        window.fbq && window.fbq('track', 'Lead');
        window.ga && window.ga('send', 'event', 'contact', 'sent', 'success');

      }, 1000);
    }
    console.log("onSubmit response", { values, Formik });
  };

  const Formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });
  const {
    //isValid,
    //dirty,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    //isSubmitting,
    //setSubmitting,
    //setErrors,
    //setTouched,
    setValues
  } = Formik;
  //console.log("Form", { isValid, values, Formik });

  const handleChange = useCallback(
    (prop, value) => {
      //console.log( 'Form Updated', prop, value, values );
      const update = { [prop]: value };
      const newValues = { ...values, ...update };
      //console.log( 'Form Updated', prop, value, {values, newValues} );
      setValues(newValues);
    },
    [values, setValues]
  );

  const resetForm = () => {
    setSubmitted(false);
    setValues({ ...values, message: "" });
  };

  //console.log("Form", { isSubmitting, isSubmitted, isValid, values, Formik });

  return (
    <div className="cd-section" {...rest}>
      {!!isSubmitting && (
        <Backdrop open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <div
        className={classes.contacts + " " + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <h2 className={classes.title}>Get in Touch</h2>
              <h5 className={classes.description}>
                Sounds good?
                <br/>
                Contact us and let&#39;s get things started.
              </h5>
              {/*
              <InfoArea
                className={classes.infoArea}
                title="Write to us"
                description={
                  <span>
                    Kemp House, 152 - 160 City Road
                    <br /> London
                    <br /> EC1V 2NX
                  </span>
                }
                icon={PinDrop}
              />
              */}

              <InfoArea
                className={classes.infoArea}
                title="Give us a call"
                description={
                  <span>
                    <a target="_blank" rel="noopener noreferrer" href="tel:+442080580556">+44 (0)208 058 0556</a>
                    <br /> 
                    Mon - Fri, 08:00-18:00
                  </span>
                }
                icon={Phone}
              />

              {false && <InfoArea
                className={classes.infoArea}
                title="Chat to us now"
                description={
                  <span>
                    Veronika is on <a target="_blank" rel="noopener noreferrer" href="https://wa.me/442081337787">+442081337787</a>
                    {/*
                    <br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://wa.me/442081446661">+442081446661</a> for Diego
                    */}
                  </span>
                }
                icon={WhatsAppIcon}
              />}

              <InfoArea
                className={classes.infoArea}
                title="Email us"
                description={
                  <span>
                    <a href="mailto:hello@fyneworks.com">hello@fyneworks.com</a>
                  </span>
                }
                icon={MailOutlineIcon}
              />

            </GridItem>
            <GridItem xs={12} sm={12} md={5} className={classes.mlAuto}>
              <Card className={classes.card1}>
                {isSubmitted ? (
                  <div className="thanks">
                    <CardHeader
                      contact
                      color="primary"
                      className={classes.textCenter}
                    >
                      <h4 className={classes.cardTitle}>Email sent</h4>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12}>
                          <Typography className={classes.thankYouHead}>
                            Thank you!
                          </Typography>
                        </GridItem>
                        <GridItem xs={12}>
                          <Typography className={classes.thankYouBody}>
                            We will be in touch as soon as possible to discuss
                            your requirements
                          </Typography>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <CardFooter className={classes.justifyContentBetween}>
                      <Button
                        color="primary"
                        className={classes.pullRight}
                        onClick={resetForm}
                      >
                        Send Another Message
                      </Button>
                    </CardFooter>
                  </div>
                ) : (
                  <form
                    disabled={!!isSubmitted}
                    action={action}
                    onSubmit={handleSubmit}
                    data-netlify-recaptcha="true"
                    data-netlify="true"
                  >
                    <CardHeader
                      contact
                      color="primary"
                      className={classes.textCenter}
                    >
                      <h4 className={classes.cardTitle}>Email us now</h4>
                    </CardHeader>
                    <CardBody>
                      {!!isSubmitting && (
                        <Backdrop open>
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      )}
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <TextField
                            label="Name"
                            onChange={event =>
                              handleChange("name", event.target.value)
                            }
                            type="text"
                            id="name"
                            autoComplete="name"
                            onBlur={handleBlur}
                            error={!!touched["name"] && !!errors["name"]}
                            helperText={errors["name"] || ""}
                            value={values.name || ""}
                            disabled={isSubmitting}
                            className={classes.formElement}
                            fullWidth
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <TextField
                            label="Phone"
                            onChange={event =>
                              handleChange("phone", event.target.value)
                            }
                            type="text"
                            id="phone"
                            autoComplete="phone"
                            onBlur={handleBlur}
                            error={!!touched["phone"] && !!errors["phone"]}
                            helperText={errors["phone"] || ""}
                            value={values.phone || ""}
                            disabled={isSubmitting}
                            className={classes.formElement}
                            fullWidth
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <TextField
                            label="Email Address"
                            onChange={event =>
                              handleChange("email", event.target.value)
                            }
                            type="text"
                            id="email"
                            autoComplete="email"
                            onBlur={handleBlur}
                            error={!!touched["email"] && !!errors["email"]}
                            helperText={errors["email"] || ""}
                            value={values.email || ""}
                            disabled={isSubmitting}
                            className={classes.formElement}
                            fullWidth
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <TextField
                            label="Your Message"
                            onChange={event =>
                              handleChange("message", event.target.value)
                            }
                            type="text"
                            id="message"
                            autoComplete="message"
                            onBlur={handleBlur}
                            error={!!touched["message"] && !!errors["message"]}
                            helperText={errors["message"] || ""}
                            value={values.message || ""}
                            disabled={isSubmitting}
                            className={classes.formElement}
                            fullWidth
                            multiline
                            rows={5}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <CardFooter className={classes.justifyContentBetween}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => handleToggle(1)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{ label: classes.label }}
                        label="I'm not a robot"
                      />
                      <Button
                        color="primary"
                        className={classes.pullRight}
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        Send Message
                      </Button>
                    </CardFooter>
                  </form>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
