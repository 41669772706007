/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import { green } from '@material-ui/core/colors';
//import { blue } from '@material-ui/core/colors';
//import { red } from '@material-ui/core/colors';
// nodejs library that concatenates classes
import classNames from "classnames";

import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import Build from "@material-ui/icons/Build";
//import Subject from "@material-ui/icons/Subject";
//import FormatPaint from "@material-ui/icons/FormatPaint";
//import Code from "@material-ui/icons/Code";
//import Dashboard from "@material-ui/icons/Dashboard";
//import Timeline from "@material-ui/icons/Timeline";
//import Group from "@material-ui/icons/Group";
import TapAndPlay from '@material-ui/icons/TapAndPlay';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import FastForwardIcon from '@material-ui/icons/FastForward';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";

const cardProject2 = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_10,w_1200/sales/young-black-adult-tradesman-standing-next-to-his-w-XBCVKST.jpg";
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

const cardProject5 = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_10,w_1200/sales/satisfied-gardener-in-a-garden-8KGXP6Q.jpg";

const useStyles = makeStyles(projectsStyle);

export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Project 4 START */}
      <div className={classes.projects + " " + classes.projects4}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Quote to Cash; and then some!
              </h2>
              {/* <a id="booked-works-logo" href="https://booked.works/"><strong>booked<span>works.co.uk</span></strong></a> */}
              <h5 className={classes.description}>
                  This simple-to-use software lets you manage your business 
                  the way you want to. It comes with an ever-growing list of features 
                  and a dedicated support team who will always be on
                  hand for you and your team. Utilise your time effectively with 
                  automation, communication, and visibility 
                  focused on bringing you to the forefront of your industry. 
              </h5>
              <div className={classes.sectionSpace} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card
                background
                className={classes.card4}
                style={{ backgroundImage: `url(${cardProject2})` }}
              >
                <CardBody background className={classes.cardBody4}>
                  <Badge color="rose">Case Study: Professional Services</Badge>
                  {/* <a href="https://bookedworks.co.uk" onClick={e => e.preventDefault}> */}
                    <h3 className={classes.cardTitle}>Powering a Mobile Workforce</h3>
                    <p className={classes.cardDescription}>
                      For teams that deliver on-premises services 
					  with quick turn-around, complex workflows and demanding compliance requirements
                    </p>
                  {/* </a> */}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <InfoArea
                className={classes.a1}
                title="BE IN CONTROL"
                description="Live tracking, instant notifications and detailed logging of every step taken throughout the day "
                icon={VisibilityIcon}
                iconColor={"pink"}
              />
              <InfoArea
                className={classes.a2}
                title="PAYMENTS MADE SIMPLE"
                description="Accept credit cards over the phone or on premises, send encrypted links for one off payments or standing orders"
                icon={CreditCardIcon}
                iconColor={"pink"}
              />
              <InfoArea
                className={classNames(classes.a3,classes.lastFeature)}
                title="CONTACTLESS EVERYTHING"
                // description="Collect signatures from your customer's phones. We leverage existing technology so everyone's smartphone is an extension of your system"
                description="Our technology allows truly contactless experience, allowing your staff and customers to use their own devices to collect photos, signatures and more"
                icon={TapAndPlay}
                iconColor={"pink"}
              />
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
			      <InfoArea
                className={classes.b1}
                title="STREAMLINE OPERATIONS"
                description="Automate many of your day-to-day tasks, client communications, invoicing and payments."
                icon={FastForwardIcon}
                iconColor={"pink"}
              />
              <InfoArea
                className={classes.b2}
                title="SEEING IS BELIEVING"
                description="A picture is worth a thousand words. Photos taken on the job go straight to your office or even the customer"
                icon={AddAPhotoIcon}
                iconColor={"pink"}
              />
              <InfoArea
                className={classNames(classes.b3,classes.lastFeature)}
                title="CUSTOMER-FOCUSED"
                description="Ensure happy customers and positive reviews with features to collect feedback that let you deal with any issues before they turn into complaints"
                icon={InsertEmoticonIcon}
                iconColor={"pink"}
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <Card
                background
                className={classes.card4}
                style={{ backgroundImage: `url(${cardProject5})` }}
              >
                <CardBody background className={classes.cardBody4}>
                  <Badge color="rose">CASE STUDY: Logistic Operations</Badge>
                  {/* <a href="https://bookedworks.co.uk" onClick={e => e.preventDefault}> */}
                    <h3 className={classes.cardTitle}>Delivering Reliability </h3>
                    <p className={classes.cardDescription}>
                      Collect photos and signatures as proof of delivery,
					            immediately emailed to customers as a secure PDF
                    </p>
                  {/* </a> */}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Project 4 END */}
    </div>
  );
}
