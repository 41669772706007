/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
//import Icon from "@material-ui/core/Icon";

// @material-ui/icons
//import Apps from "@material-ui/icons/Apps";
//import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ViewDay from "@material-ui/icons/ViewDay";
//import Dns from "@material-ui/icons/Dns";
import Build from "@material-ui/icons/Build";
//import ListIcon from "@material-ui/icons/List";
//import People from "@material-ui/icons/People";
//import Assignment from "@material-ui/icons/Assignment";
//import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Chat from "@material-ui/icons/Chat";
//import Call from "@material-ui/icons/Call";
import YouTube from "@material-ui/icons/YouTube";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
//import ViewCarousel from "@material-ui/icons/ViewCarousel";
//import AccountBalance from "@material-ui/icons/AccountBalance";
//import ArtTrack from "@material-ui/icons/ArtTrack";
//import ViewQuilt from "@material-ui/icons/ViewQuilt";
//import LocationOn from "@material-ui/icons/LocationOn";
//import Fingerprint from "@material-ui/icons/Fingerprint";
//import AttachMoney from "@material-ui/icons/AttachMoney";
//import Store from "@material-ui/icons/Store";
//import AccountCircle from "@material-ui/icons/AccountCircle";
//import PersonAdd from "@material-ui/icons/PersonAdd";
//import Layers from "@material-ui/icons/Layers";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
//import LineStyle from "@material-ui/icons/LineStyle";
//import Error from "@material-ui/icons/Error";
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import PeopleIcon from '@material-ui/icons/People';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderSimple(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = window.smoothScroll = (e, target) => {
    var isMobile = navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
    );
    if (isMobile) {
      console.warn("Native scroll into view", {e,target,isMobile});
      // if we are on mobile device the scroll into view will be managed by the browser
    } else {
      e.preventDefault();
      var targetScroll = document.getElementById(target);
      if(!targetScroll){
        console.warn("Browser scroll quite - cannot find target", {e,target,isMobile,targetScroll});
        return;
      }
      console.log("Browser scroll into view", {e,target,isMobile,targetScroll});
      scrollGo(document.documentElement, getOffset(targetScroll).top, 1250);
    }
  };
  const getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;
    console.log("Browser scroll scrollGo", {element,to,duration,start,change,currentTime,increment});

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  //var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
		<ListItem className={classes.listItem}>
		<CustomDropdown
			noLiPadding
			navDropdown
			hoverColor={dropdownHoverColor}
			buttonText="Learn More"
			buttonProps={{
			className: classes.navLink,
			color: "transparent"
			}}
			buttonIcon={ViewDay}
			dropdownList={[
				<Link
					to="#video"
					className={classes.dropdownLink}
					onClick={e => smoothScroll(e, "video")}
				>
					<YouTube className={classes.dropdownIcons} /> Introduction
				</Link>,
				<Link
					to="#flagship"
					className={classes.dropdownLink}
					onClick={e => smoothScroll(e, "flagship")}
				>
					<DevicesOtherIcon className={classes.dropdownIcons} /> Our Products
				</Link>,
				<Link
					to="#intro"
					className={classes.dropdownLink}
					onClick={e => smoothScroll(e, "intro")}
				>
					<HelpOutlineIcon className={classes.dropdownIcons} /> Why Fyneworks?
				</Link>,
				<Link
					to="#features"
					className={classes.dropdownLink}
					onClick={e => smoothScroll(e, "features")}
				>
					<Build className={classes.dropdownIcons} /> Features
				</Link>,
				<Link
					to="#tailored"
					className={classes.dropdownLink}
					onClick={e => smoothScroll(e, "tailored")}
				>
					<ImportantDevicesIcon className={classes.dropdownIcons} /> Tailored
        </Link>,
				<Link
					to="#team"
					className={classes.dropdownLink}
					onClick={e => smoothScroll(e, "team")}
				>
					<PeopleIcon className={classes.dropdownIcons} /> Our Team
        </Link>,
				<Link
					to="#testimonials"
					className={classes.dropdownLink}
					onClick={e => smoothScroll(e, "testimonials")}
				>
					<Chat className={classes.dropdownIcons} /> Testimonials
				</Link>,
				<Link
					to="#contact"
					className={classes.dropdownLink}
					onClick={e => smoothScroll(e, "contact")}
				>
					<Email className={classes.dropdownIcons} /> Contact
				</Link>
				]}
			/>
		</ListItem>
      {/* <Link
        to="#contact"
        className={classes.dropdownLink}
        onClick={e => smoothScroll(e, "contact")}
      >
        <Email className={classes.dropdownIcons} /> Email
      </Link> */}
      {/* 
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Contact Us"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={ViewDay}
          dropdownList={[
            <Link
              to="#contact"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "contact")}
            >
              <Email className={classes.dropdownIcons} /> Email
            </Link>
          ]}
        />
      </ListItem>
      */}
      <ListItem className={classes.listItem}>
      <Link
            to="#contact"
            className={classes.dropdownLink}
            onClick={e => smoothScroll(e, "contact")}
          >
        <Button
          to="#contact"
          color={window.innerWidth < 960 ? "info" : "white"}
          className={classes.navButton}
          round
        >
            <Email className={classes.dropdownIcons} /> Get in touch
        </Button>
          </Link>
{/*         
        <Button
          href="tel:+442080580556"
          color={window.innerWidth < 960 ? "info" : "white"}
          className={classes.navButton}
          round
        >
          <Phone className={classes.icons} /> Call Now
        </Button> */}
      </ListItem>
    </List>
  );
}

HeaderSimple.defaultProps = {
  hoverColor: "primary"
};

HeaderSimple.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
