/*eslint-disable*/
import React, { useState } from "react";
import {GoogleReviews} from 'components/GoogleReviews.js';

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderSimple from "components/Header/HeaderSimple.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
// import SectionHero from "views/HomePage/Sections/SectionHero.js";
import SectionDescription from "views/HomePage/Sections/SectionDescription.js";
import SectionComponents from "views/HomePage/Sections/SectionComponents.js";
import SectionUniversal from "views/HomePage/Sections/SectionUniversal.js";
import SectionFooter from "views/HomePage/Sections/SectionFooter.js";
import SectionProducts from "views/HomePage/Sections/SectionProducts";
import SectionContent from "views/HomePage/Sections/SectionContent.js";
// import SectionSections from "views/HomePage/Sections/SectionSections.js";
// import SectionExamples from "views/HomePage/Sections/SectionExamples.js";
// import SectionFAQs from "views/HomePage/Sections/SectionFAQs.js";
import SectionFlagship from "views/HomePage/Sections/SectionFlagship.js";
import SectionTeam from "views/HomePage/Sections/SectionTeam.js";
import SectionTestimonials from "views/HomePage/Sections/SectionTestimonials.js";
import SectionContact from "views/HomePage/Sections/SectionContact.js";

// import logoImageLight from "assets/img/fw-logo-light.png";
// import logoImageBanner from "assets/img/brand/logo-banner.png";// "https://res.cloudinary.com/fynewo/image/upload/v1590576031/logo/logo-banner.png";
const logoImageBanner = "https://res.cloudinary.com/fynewo/image/upload/f_auto,e_trim/v4534/logo/fyneworks_logo_full_reversed.png";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

import ModalVideo from 'react-modal-video'

const useStyles = makeStyles({...presentationStyle, ...landingPageStyle});

export default function HomePage() {
  React.useEffect(() => {
    if(typeof window === "undefined") return;
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  const [ isVideoOpen, setVideoOpen ] = useState(false);

  return (
    <div id="video">
      
      <Header
        color="transparent"
        brand={
          <img src={logoImageBanner} height={42}/>
        }
        links={<HeaderSimple dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "info"
        }}
        // {...rest}
      />

      <Parallax image={("https://res.cloudinary.com/fynewo/image/upload/f_auto,q_50/sales/repairman-with-arms-crossed-leaning-against-his-va-PQ22T5G.jpg")} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>Work smarter,<br/>get more done</h1>
              <h4>
                Job Management App for Small Businesses
              </h4>
              <br />
              <ModalVideo channel='youtube' isOpen={isVideoOpen} videoId='91k3M9v3LQU' onClose={() => setVideoOpen(false)} />
              <Button
                color="danger"
                size="lg"
                // href="https://youtu.be/91k3M9v3LQU"
                // data-video-id="PZhp3A9D8Yc"
                // className="js-modal-btn"
                target="_blank"
                onClick={()=>setVideoOpen(true) && false}
              >
                <i className="fas fa-play"></i>
                Watch video
              </Button>

              <Button
                color="primary"
                size="lg"
                href="https://try.remover.works/demo"
                target="_blank"
                className="contact-link"
              >
                <i className="fas fa-eye"></i>
                Try Now
              </Button>
              {/* 
              <Button
                color="primary"
                size="lg"
                href="#contact"
                className="contact-link"
              >
                <i className="fas fa-envelope"></i>
                Enquire Now
              </Button> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames('GoogleReviews', classes.main, classes.mainRaised)}>
        <GoogleReviews/>
      </div>
      
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionFlagship id="flagship" />
        <SectionDescription id="intro" />
        <SectionComponents id="intro2" />
        <SectionContent id="intro3" />
      </div>

      <SectionUniversal id="features" />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionProducts id="projects" />
        <SectionTeam id="team" />
        <SectionTestimonials id="testimonials" />
      </div>

      <SectionContact id="contact" />

      <SectionFooter classes={classes} />

    </div>
  );
}
