import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight, FaQuoteRight } from "react-icons/fa";
import ReadMoreReact from "read-more-react";
import { Rating } from "react-simple-star-rating";


const minimumLength = 100;
const idealLength = 200;
const maxLength = 300;
const readMoreText = "Read Full Review";

export const GoogleReviews = () => {
  const [reviews, setReviews] = useState([]);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    // using google server api
    // https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJfaExtesFdkgRrvY3Di84NSs&fields=reviews&key=AIzaSyDrI5dg_QZqgK3lhk5sMPcgeC_Vz1WWv58
    // /.netlify/functions/reviews
    fetch("/.netlify/functions/reviews")
      .then(x => x.json())
      .then(res => {
        console.log("GoogleReviews got reviews", res);
        setReviews(res.reviews);
      });
  }, []);
  if (!reviews.length) return null;

  const { name, job, image, text, rating } = reviews[index];

  const checkNumber = number => {
    if (number > reviews.length - 1) {
      return 0;
    } else if (number < 0) {
      return reviews.length - 1;
    }
    return number;
  };

  const nextPerson = () => {
    setIndex(index => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };

  const prevPerson = () => {
    setIndex(index => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    });
  };

  const randomPerson = () => {
    let randomNumber = Math.floor(Math.random() * reviews.length);
    if (randomNumber === index) {
      randomNumber = index + 1;
    }
    setIndex(checkNumber(randomNumber));
  };

  return (
    <article className="review">
      <div className="img-container">
        <img src={image} alt={name} className="person-img" />
        <span className="quote-icon">
          <FaQuoteRight />
        </span>
      </div>
      <h4 className="author">{name}</h4>
      <p className="jon">{job}</p>
      <Rating readonly={true} initialValue={rating} />
      <div className="info">
        <ReadMoreReact
          key={index}
          text={text}
          min={minimumLength}
          ideal={idealLength}
          max={maxLength}
          readMoreText={readMoreText}
        />
      </div>

      <div className="button-container">
        <button className="prev-btn" onClick={prevPerson}>
          <FaChevronLeft />
        </button>
        <button className="next-btn" onClick={nextPerson}>
          <FaChevronRight />
        </button>
      </div>
      <button className="random-btn" onClick={randomPerson}>
        Suprise Me!
      </button>
    </article>
  );
};

export default GoogleReviews;
