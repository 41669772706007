import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import LazyLoad from 'react-lazyload';

//import diego from "assets/img/faces/diego.jpg";
const diego = "https://res.cloudinary.com/fynewo/image/upload/q_auto,f_auto,w_240/sales/faces/diego.jpg";

//import veronika from "assets/img/faces/veronika.jpg";
//const veronika = "https://res.cloudinary.com/fynewo/image/upload/q_auto,f_auto,w_240/sales/faces/veronika.jpg";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Team 1 START */}
      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Our Team</h2>
              <h5 className={classes.description}>
                <em>Our</em> team is <em><b>your team</b></em> when you join Fyneworks
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {/* <GridItem xs={12} sm={6} md={6}> */}
            <GridItem xs={12}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="https://www.linkedin.com/in/fyneworks/">
                    <LazyLoad>
                      <img src={diego} alt="..." className={classes.img} />
                    </LazyLoad>
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Diego Alto</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>CEO / Consultant</h6>
                  </Muted>
                  <p className={classes.description}>
                    Never shying away from a challenge, Diego has worked
                    in various industries to bring them
                    affordable software solutions since 2002.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            {/*
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="https://www.linkedin.com/in/veronika-kusak-64023491/">
                    <LazyLoad>
                      <img src={veronika} alt="..." className={classes.img} />
                    </LazyLoad>
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Veronika Kusak</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>ACCOUNT MANAGER</h6>
                  </Muted>
                  <p className={classes.description}>
                    With a 10 year background in e-commerce, working in everything 
                    from sales and dispatch, to marketing and project management, 
                    Veronika has lots of energy and experience to bring to you and your business
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            */}
          </GridContainer>
        </div>
      </div>
      {/* Team 1 END */}
    </div>
  );
}
