import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Code from "@material-ui/icons/Code";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Dashboard from "@material-ui/icons/Dashboard";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccessTime from "@material-ui/icons/AccessTime";
import AttachMoney from "@material-ui/icons/AttachMoney";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";


import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";
const useStyles = makeStyles(featuresStyle);
const bg9 = "https://res.cloudinary.com/fynewo/image/upload/f_auto,q_10/sales/diy-and-new-technologies-PEU58CF.jpg";

export default function SectionCards({ ...rest }) {

	const classes = useStyles();
	//console.log('SectionCards classes', classes);
	return (
		<div className="cd-section" {...rest}>
			<div
				className={classes.features5}
				style={{ backgroundImage: `url(${bg9})` }}
			>
				<GridContainer>
				<GridItem
					xs={12}
					sm={8}
					md={8}
					className={
					classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
					}
				>
					<h2 className={classes.title}>The complete toolkit</h2>
				</GridItem>
				<div className={classes.container}>
					<GridContainer className={classes.gridContainer}>
					<GridItem xs={12} sm={4} className={classes.gridItem}>
						<InfoArea
						vertical
						className={classes.infoArea5}
						icon={Code}
						title="Capture Enquiries"
						description={
							<p>
							Fyneworks puts you ahead of the competition with customer-friendly 
							data capture forms made just for you so you generate more leads
							</p>
						}
						iconColor="info"
						/>
					</GridItem>
					<GridItem xs={12} sm={4} className={classes.gridItem}>
						<InfoArea
						vertical
						className={classes.infoArea5}
						icon={FormatPaint}
						title="Smart Quotes"
						description={
							<p>
							Let customers accept quotes through a secure link.
							Attach purchase orders and images, 
							generate PDFs, collect signatures, etc... 
							</p>
						}
						iconColor="danger"
						/>
					</GridItem>
					<GridItem xs={12} sm={4} className={classes.gridItem}>
						<InfoArea
						vertical
						className={classes.infoArea5}
						icon={Dashboard}
						title="Manage Sales"
						description={
							<p>
							Track conversions and view a customer's history with ease. All your data is stored in
							one place so you can see the big picture. 
							</p>
						}
						iconColor="primary"
						/>
					</GridItem>
					</GridContainer>
					<GridContainer className={classes.gridContainer}>
					<GridItem xs={12} sm={4} className={classes.gridItem}>
						<InfoArea
						vertical
						className={classes.infoArea5}
						icon={AttachMoney}
						title="Get Paid with Ease"
						description={
							<p>
							Collect payment online, by card or direct debit. Automatic invoicing, 
							monthly statements, subscription services and more.
							</p>
						}
						/>
					</GridItem>
					<GridItem xs={12} sm={4} className={classes.gridItem}>
						<InfoArea
						vertical
						className={classes.infoArea5}
						icon={AccessTime}
						title="Get The Job Done"
						description={
							<p>
							Clear and detailed job requirements. Meet and exceed your customer's 
							expectations with direct communicatons, SMS and WhatsApp notifications.
							</p>
						}
						/>
					</GridItem>
					<GridItem xs={12} sm={4} className={classes.gridItem}>
						<InfoArea
						vertical
						className={classes.infoArea5}
						icon={ViewCarousel}
						title="Great Visibility"
						description={
							<p>
							Stay on top of everything with time-tracking and instant 2-way communication. 
							Collect all the data you need while you're on-the-job. 
							</p>
						}
						/>
					</GridItem>
					</GridContainer>
				</div>
				</GridContainer>
			</div>
		</div>
	);
}
