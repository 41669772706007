import React from "react";
// react component for creating beautiful carousel
// import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import FormatQuote from "@material-ui/icons/FormatQuote";
// import Star from "@material-ui/icons/Star";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Muted from "components/Typography/Muted.js";
// import Warning from "components/Typography/Warning.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";

// import dg2 from "assets/img/dg2.jpg";
import cardProfileAnian from "assets/img/testimonials/anian.jpg";
import cardProfileGuy from "assets/img/testimonials/guy.jpg";
import cardProfileAllan from "assets/img/testimonials/allan.jpg";
// import kendall from "assets/img/faces/kendall.jpg";
// import christian from "assets/img/faces/christian.jpg";

import LazyLoad from 'react-lazyload';


const useStyles = makeStyles(testimonialsStyle);

export default function SectionTestimonials({ ...rest }) {
  const classes = useStyles();

  /*
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  */
  return (
    <div className="cd-section" {...rest}>
      {/* Testimonials 3 START */}
      <div className={classes.testimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>What Clients Say</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  <a href="#tbc" onClick={e => e.preventDefault()}>
                    <LazyLoad>
                      <img src={cardProfileAnian} alt="..." />
                    </LazyLoad>
                  </a>
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Anian S.</h4>
                  <Muted>
                    <h6>FOUNDER @ ONLY ROSES</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}Excellent skills and excellent understanding in how to put content and technology together{'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  <a href="#tbc" onClick={e => e.preventDefault()}>
                    <LazyLoad>
                      <img src={cardProfileGuy} alt="..." />
                    </LazyLoad>
                  </a>
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Guy H.</h4>
                  <Muted>
                    <h6>DIRECTOR @ NCOMP@SS</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}There is nothing [Fyneworks] cannot do... reliable and can solve almost any problem.{'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  <a href="#tbc" onClick={e => e.preventDefault()}>
                    <LazyLoad>
                      <img src={cardProfileAllan} alt="..." />
                    </LazyLoad>
                  </a>
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Allan S.</h4>
                  <Muted>
                    <h6>SALES MANAGER @ P&amp;N</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}Customisability is impressive, functionality, depth of analytics. Support responds fairly rapidly{'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 3 END */}
    </div>
  );
}
