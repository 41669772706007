/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

const treebuddy = "https://res.cloudinary.com/fynewo/image/upload/q_auto,f_auto,w_1200/sales/product-screenshots//treebuddy.png";
const junkworks = "https://res.cloudinary.com/fynewo/image/upload/q_auto,f_auto,w_1200/sales/product-screenshots//junkworks.png";
const roseworks = "https://res.cloudinary.com/fynewo/image/upload/q_auto,f_auto,w_1200/sales/product-screenshots//roseworks.png";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import LazyLoad from 'react-lazyload';

const useStyles = makeStyles(projectsStyle);

export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
		
      {/* Project 2 START */}
      <div id="tailored" className={classes.projects}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
				        Tailored Solutions
              </h2>
              <h5 className={classes.description}>
				        Below are a few of the Fyneworks systems built for individual clients
				        that wanted to stand head and shoulders above their competition.
                <b>We have spent years building bespoke software for businesses 
                that couldn't find what they needed off-the-shelf.</b> 
                &nbsp;
                We have a track record of delivering comprehensive and reliable 
                systems that do exactly what you need them to do.
                <br/><br/>
                No challenge is too great, no requirement is too niche.
              </h5>
              <div className={classes.sectionSpace} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card plain className={classes.card2}>
                {/* <a
                  href="#"
                  target="_blank"
                > */}
                  <CardHeader image plain>
                    <LazyLoad>
                      <img
                        src={treebuddy}
                        alt="Delivery Logistic Software"
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage:
                          "url('"+treebuddy+"')",
                          opacity: "1"
                        }}
                      />
                    </LazyLoad>
                  </CardHeader>
                {/* </a> */}
                <CardBody plain>
                  {/* <a
                    href="#"
                    target="_blank"
                  > */}
                    <h4 className={classes.cardTitle}>
                      TreeBuddy
                    </h4>
                  {/* </a> */}
                  <h6 className={classes.description}>Pines and Needles</h6>
                  <p
                    className={classes.description + " " + classes.marginTop20}
                  >
                    Christmas tree delivery software with robust logistic features
					          built to scale and adapt to huge spikes in volume 
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card plain className={classes.card2}>
                {/* <a
                  href="#"
                  target="_blank"
                > */}
                  <CardHeader image plain>
                    <LazyLoad>
                      <img
                        src={junkworks}
                        alt="Waste Management Software"
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage:
                          "url('"+junkworks+"')",
                          opacity: "1"
                        }}
                      />
                    </LazyLoad>
                  </CardHeader>
                {/* </a> */}
                <CardBody plain>
                  {/* <a
                    href="#" ignore="https://bookedworks.co.uk/"
                    dont-target="_blank"
                  > */}
                    <h4 className={classes.cardTitle}>
                      JunkWorks
                    </h4>
                  {/* </a> */}
                  <h6 className={classes.description}>We Clear Junk</h6>
                  <p
                    className={classes.description + " " + classes.marginTop20}
                  >
                    Waste management solution tracking every movement from 
                    collection to recycling with comprehensive reports
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card plain className={classes.card2}>
                {/* <a
                  href="#" ignore="https://bookedworks.co.uk/"
                  dont-target="_blank"
                > */}
                  <CardHeader image plain>
                    <LazyLoad>
                      <img
                        src={roseworks}
                        alt="Florist Sales Software"
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage:
                            "url('"+roseworks+"')",
                          opacity: "1"
                        }}
                      />
                    </LazyLoad>
                  </CardHeader>
                {/* </a> */}
                <CardBody plain>
                  {/* <a
                    href="#" ignore="https://bookedworks.co.uk/"
                    dont-target="_blank"
                  > */}
                    <h4 className={classes.cardTitle}>
                      RoseWorks
                    </h4>
                  {/* </a> */}
                  <h6 className={classes.description}>Only Roses</h6>
                  <p
                    className={classes.description + " " + classes.marginTop20}
                  >
                    Retail solution for a global brand
                    powering websites and stores with click-and-collect,
                    point-of-sale and delivery features
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Project 2 END */}
    </div>
  );
}
